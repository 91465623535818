.white-card {
  $self: &;
  display: flex;
  min-height: rem(335);
  background-color: var(--c-white);

  &__left,
  &__right {
    max-width: 50%;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: rem(40) rem(30) rem(35);
    height: 100%;
  }

  &__title {
    margin-bottom: rem(10);
  }

  &__subtitle {
    margin-bottom: rem(10);
  }

  &__btn {
    margin-top: rem(35);
  }

  &__text {
    p {
      &:not(:last-child) {
        margin-bottom: rem(15);
      }
    }

    ul {
      font-size: rem(14);
      line-height: rem(21);

      li {
        position: relative;
        padding-left: rem(17);

        &::after {
          content: '';
          position: absolute;
          top: rem(7);
          left: 0;
          width: rem(7);
          height: rem(7);
          border-radius: 50%;
          background-color: var(--c-blue);
        }

        &:not(:last-child) {
          margin-bottom: rem(10);
        }
      }
    }
  }

  &__image {
    position: relative;
    height: 100%;

    img {
      @include rImage();
    }
  }

  &__service {
    margin-bottom: rem(25);
  }

  &__tarif {
    margin-top: rem(20);
  }

  &__price {
    margin-top: rem(20);
  }

  &__discount {
    font-style: italic;
    font-size: rem(15);
    line-height: rem(23);
    letter-spacing: 0.11em;
    color: var(--c-blue);
  }

  &--news {
    min-height: rem(399);

    #{$self}__btn {
      margin-top: rem(20);
    }

    #{$self}__title {
      margin-bottom: rem(15);
    }
  }
}
