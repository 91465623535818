.weather-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__item {
    &:not(:last-child) {
      margin-right: rem(20);
    }
  }
}

