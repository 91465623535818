.section {
  $self: &;

  padding: rem(60) 0;

  &__title {
    margin-bottom: rem(25);
    color: var(--c-blue);
  }

  &--blue {
    background: rgba(0, 87, 255, 0.15);
  }

  &--dark {
    color: var(--c-white);
    background-color: var(--c-blue);

    #{$self}__title {
      color: var(--c-white);
    }
  }
}
