.main-contacts {
  background-image: url('/assets/img/contacts-bg.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: top right;

  &__content {
    display: flex;
    align-items: flex-start;
  }

  &__left {
    @include media-breakpoint-down(md) {
      margin-bottom: rem(40);
    }
  }
}
