/**
 * Buttons
 */

.btn {
  display: inline-block;
  min-width: rem(211);
  padding: rem(12) rem(20) rem(12);
  background-color: transparent;
  border: 1px solid transparent;
  vertical-align: middle;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }

  &--primary {
    background-color: var(--c-blue);
    color: var(--c-white);
    transition: background-color 0.3s linear;
  }

  &--secondary {
    background-color: transparent;
    color: var(--c-blue);
    border-color: var(--c-blue);
    transition: background-color 0.3s linear, color 0.3s linear;

    @include hover() {
      color: var(--c-white);
      background-color: var(--c-blue);
    }

    &._active {
      color: var(--c-white);
      background-color: var(--c-blue);
    }
  }

  &--medium {
    min-width: rem(250);
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--form {
    min-width: rem(250);
  }
}
