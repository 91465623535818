.contacts {
  &__item {
    display: flex;

    &:not(:last-child) {
      margin-bottom: rem(15);
    }

    p {
      &:not(:last-child) {
        margin-bottom: rem(10);
      }
    }
  }

  &__icon {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: rem(15);
    margin-top: rem(2);
    width: rem(18);
    height: rem(18);
  }
}
