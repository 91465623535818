.work-block {
  position: relative;

  &__title {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--c-grey);
    text-transform: uppercase;
    white-space: nowrap;
    pointer-events: none;

    @include media-breakpoint-down(sm) {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      transform-origin: 50% 25%;
    }
  }
}
