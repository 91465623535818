.contact-card {
  display: flex;
  align-items: flex-start;

  &__icon {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(43);
    height: rem(43);
    margin-right: rem(10);

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__content {
    padding-top: rem(12);
  }

  &__title {
    font-weight: 700;
    margin-bottom: rem(10);
  }
}
