/**
 * Colors
 */

$color-base: #4a5568 !default;
$color-primary: #c8e0e3 !default;
$color-secondary: #aec4c7 !default;
$color-blue: #c8e0e3 !default;
$color-grey: #737373 !default;
$color-white: #fff !default;
$color-light: #f2f2f2 !default;
$color-dark: #2d3748 !default;
$color-black: #000 !default;
$color-success: #00b300 !default;
$color-error: #c00 !default;
$color-light-grey: #ececed !default;
$color-grey-t: #f9f9f9 !default;
$color-grey-b: #979797 !default;
$color-light-g: #dedede !default;

// Used for generating CSS utility classes
$colors: (
  'base': $color-base,
  'primary': $color-primary,
  'secondary': $color-secondary,
  'white': $color-white,
  "light": $color-light,
  "dark": $color-dark,
  'black': $color-black,
  'success': $color-success,
  'error': $color-error
) !default;

// Links
$color-link-color: #007bff !default;
$color-link-color-hover: darken($color-link-color, 20%) !default;
