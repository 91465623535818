.slider-navigation-btn {
  position: absolute;
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(45);
  height: rem(45);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

  svg {
    width: rem(8);
    height: rem(12);
  }

  &--next {
    right: rem(25);

    svg {
      transform: rotate(180deg);
      margin-right: rem(-1);
    }
  }

  &--prev {
    left: rem(25);

    svg {
      margin-left: rem(-1);
    }
  }
}
