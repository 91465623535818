.main-section {
  position: relative;
  padding: rem(90) 0 rem(90);
  background-color: var(--c-blue);
  color: var(--c-white);
  min-height: rem(480);

  @include media-breakpoint-down(md) {
    padding: rem(60) 0;
  }

  &__inner {
    position: relative;
  }

  &__content {
    max-width: rem(672);
  }

  &__title {
    margin-top: 0;
    margin-bottom: rem(60);

    @include media-breakpoint-down(md) {
      margin-bottom: rem(30);
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: rem(20);
    }
  }

  &__text {
    max-width: rem(580);
    font-size: rem(20);
    line-height: rem(30);
  }

  &__image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: rem(100);
    width: rem(404);
    height: rem(380);

    @include media-breakpoint-down(lg) {
      right: rem(-30);
    }

    @include media-breakpoint-down(md) {
      position: relative;
      right: 0;
      top: 0;
      transform: none;
      margin: 0 auto rem(30);
    }

    @include media-breakpoint-down(sm) {
      width: rem(300);
      height: rem(282);
    }

    img {
      @include rImage();
    }
  }

  &__women {
    position: absolute;
    bottom: rem(-187);
    right: 0;
    width: rem(156);
    height: rem(259);
    pointer-events: none;

    @include media-breakpoint-down(lg) {
      bottom: rem(-220);
    }

    @include media-breakpoint-down(md) {
      bottom: rem(-180);
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    img {
      @include rImage();
    }
  }

  &__scroll {
    position: absolute;
    top: rem(-34);
    right: rem(78);
    width: rem(16);
    height: rem(226);
    pointer-events: none;

    @include media-breakpoint-down(lg) {
      display: none;
    }

    img {
      @include rImage();
    }
  }
}
