.btn-message {
  display: inline-flex;
  align-items: center;
  font-size: rem(16);
  line-height: rem(24);
  font-weight: 700;

  svg {
    flex: 0 0 auto;
    width: rem(54);
    height: rem(50);
    margin-left: rem(14);
  }
}
