.all-page {

  &__text {
    margin-bottom: rem(40);
    text-align: center;
  }

  &__section {
    max-width: rem(1080);
    margin: 0 auto;
  }

  &__title {
    margin-top: rem(60);
    margin-bottom: rem(60);
    text-align: center;
  }

  &__btn {
    margin-top: rem(15);
    text-align: center;
  }
}
