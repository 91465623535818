h1,
.h1 {
  font-family: 'Inter', Arial, sans-serif;
  font-size: rem(36);
  line-height: rem(48);
  font-weight: 700;
  letter-spacing: -1px;

  @include media-breakpoint-down(sm) {
    font-size: rem(30);
    line-height: rem(42);
  }
}

h2,
.h2 {
  font-family: 'Inter', Arial, sans-serif;
  font-size: rem(28);
  line-height: rem(30);
  letter-spacing: -0.03em;
  font-weight: 700;

  @include media-breakpoint-down(sm) {
    font-size: rem(24);
    line-height: rem(26);
  }
}

h3,
.h3 {
  font-family: 'Inter', Arial, sans-serif;
  font-size: rem(26);
  line-height: rem(30);
  letter-spacing: -0.02em;
  font-weight: 700;

  @include media-breakpoint-down(sm) {
    font-size: rem(20);
    line-height: rem(28);
  }
}

.title {
  font-size: rem(24);
  line-height: rem(32);
}

h4,
.h4 {
  font-family: 'Playfair Display', Arial, sans-serif;
  font-size: rem(20);
  line-height: rem(27);
  font-weight: 600;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

h5,
.h5 {
  font-family: 'Playfair Display', Arial, sans-serif;
  font-size: rem(18);
  line-height: rem(24);
  font-weight: 600;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.p {
  font-size: rem(16);
  line-height: rem(24);
  letter-spacing: 0.11em;

  &--xs {
    font-size: rem(14);
    line-height: rem(26);
  }

  &--small {
    font-size: rem(15);
    line-height: rem(22);
  }

  &--md {
    font-size: rem(18);
    line-height: rem(28);
  }

  &--medium {
    font-size: rem(20);
    line-height: rem(25);
  }

  &--big {
    font-weight: 600;
    font-size: rem(22);
    line-height: rem(27);
  }
}

.text-block {

  p {
    &:not(:last-child) {
      margin-bottom: rem(20);
    }
  }

  h3,
  .h3 {
    margin-bottom: rem(20);
    margin-top: rem(30);
  }

  strong {
    font-weight: 600;
  }

  ul {
    li {
      position: relative;
      padding-left: rem(18);

      &::before {
        content: '';
        position: absolute;
        top: 0.56em;
        left: 0;
        width: rem(6);
        height: rem(6);
        border-radius: 50%;
        background-color: var(--c-black);
      }

      &:not(:last-child) {
        margin-bottom: rem(14);
      }
    }
  }

  & > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &--small {
    font-size: rem(14);
    line-height: rem(20);
  }
}
