.prices {
  display: flex;
  align-items: center;

  &__discount {
    padding: rem(3) rem(6);
    margin-right: rem(15);
    background-color: var(--c-blue);
    color: var(--c-white);
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }

  &__item {
    font-size: rem(28);
    line-height: rem(35);
    font-weight: 600;
    color: var(--c-blue);

    &:not(:last-child) {
      margin-right: rem(10);
    }

    span {
      font-size: rem(15);
      line-height: rem(19);
      font-weight: 400;
    }

    &--old {
      color: var(--c-black);
      text-decoration: line-through;
      font-size: rem(15);
      line-height: rem(19);
      font-weight: 400;
    }
  }
}
