.icon {
  display: block;

  &--phone {
    width: rem(18);
    height: rem(18);
  }

  &--mail {
    width: rem(18);
    height: rem(13);
  }

  &--clock {
    width: rem(18);
    height: rem(18);
  }

  &--vk {
    width: rem(20);
    height: rem(14);
  }

  &--tg {
    width: rem(22);
    height: rem(22);
  }

  &--ok {
    width: rem(11);
    height: rem(19);
  }

  &--wa {
    width: rem(23);
    height: rem(23);
  }
}
