.header {
  $self: &;
  color: var(--c-black);

  &__logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(14) rem(18);
    border-right: 1px solid rgba(#c4c4c4, 0.4);
    border-left: 1px solid rgba(#c4c4c4, 0.4);

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__inner {
    display: flex;
    padding-top: rem(8);
    padding-bottom: rem(8);
  }

  &__content {
    display: flex;
    margin-left: auto;
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(14) rem(20);
    border-right: 1px solid rgba(#c4c4c4, 0.4);
    border-left: 1px solid rgba(#c4c4c4, 0.4);
    font-size: rem(16);
    line-height: rem(18);

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(14) rem(20);
    margin-left: -1px;
    border-right: 1px solid rgba(#c4c4c4, 0.4);
    border-left: 1px solid rgba(#c4c4c4, 0.4);
    font-size: rem(12);
    line-height: rem(20);

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__nav {
    background-color: var(--c-blue);

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
