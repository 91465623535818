.socials {
  $self: &;
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    &:not(:last-child) {
      margin-right: rem(20);
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(40);
    height: rem(40);
    border: 2px solid var(--c-white);
    border-radius: 50%;
    transition: background-color 0.3s linear;

    svg {
      fill: var(--c-white);
      transition: fill 0.3s linear;
    }

    @include hover() {
      background-color: var(--c-white);

      svg {
        fill: var(--c-dark-blue);
      }
    }
  }

  &--black {
    #{$self}__link {
      border-color: var(--c-black);

      svg {
        fill: var(--c-black);
      }

      @include hover() {
        background-color: var(--c-black);

        svg {
          fill: var(--c-white);
        }
      }
    }
  }
}
