.all-card {
  text-align: center;

  &__icon {
    position: relative;
    width: rem(90);
    height: rem(50);
    margin-bottom: rem(20);
    margin-left: auto;
    margin-right: auto;

    img {
      @include rImage();
    }
  }

  &__text {
    margin-top: rem(10);
    line-height: rem(21);
  }
}
