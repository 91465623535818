.page-header {
  position: relative;
  height: rem(257);

  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(38, 39, 41, 0.4);
  }

  img {
    @include rImage();
    z-index: 1;
  }
}
