.lang-switch {
  position: relative;

  &__title {
    text-transform: uppercase;
  }

  &__btn {
    display: flex;
    align-items: center;
  }

  &__icon {
    flex: 0 0 auto;
    width: rem(12);
    height: rem(7);
    margin-left: rem(2);
    fill: none;
    stroke: currentColor;
  }

  &__content {
    display: none;
  }
}
