.file-input {
  position: relative;

  &__target {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(10);
    background-color: var(--c-grey-3);
    cursor: pointer;
  }

  &__icon {
    position: relative;
    width: rem(20);
    height: rem(20);
    margin-right: rem(15);

    img {
      @include rImage();
    }
  }
}
