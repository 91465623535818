.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(10) 0;
  color: var(--c-white);

  &__item {
    flex: 1 1 auto;
    margin-right: -1px;
    border-right: 1px solid var(--c-white);
    border-left: 1px solid var(--c-white);
    text-align: center;
  }

  &__link {
    display: block;
    padding: rem(15) rem(20);
    font-size: rem(18);
    line-height: rem(20);
    white-space: nowrap;
  }
}
