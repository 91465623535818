.numbers-list {
  &__item {
    &:not(:first-child) {
      margin-top: rem(76);

      @include media-breakpoint-down(sm) {
        margin-top: rem(40);
      }
    }
  }
}
