.nav-pane {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: calc((100% - #{rem(1240)}) / 2 + #{rem(356)});
  height: 100vh;
  padding: rem(174) rem(30) rem(66) calc((100% - #{rem(1240)}) / 2);
  background: rgba(0, 56, 72, 0.9);
  color: var(--c-white);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s linear, visibility 0.3s linear;

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__menu {
    margin-bottom: rem(50);
  }

  &__contacts {
    margin-top: auto;
    max-width: rem(286);

    p {
      &:not(:last-child) {
        margin-bottom: rem(10);
      }
    }
  }

  &._open {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}
