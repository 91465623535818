.services {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: rem(44);
    height: rem(44);
    border-radius: 50%;
    border: 1px solid var(--c-grey-2);

    &:not(:last-child) {
      margin-right: rem(4);
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
