.c-input {
  $self: &;
  position: relative;

  &__target {
    display: block;
    width: 100%;
    padding: rem(10);
    font-size: rem(14);
    line-height: rem(18);
    letter-spacing: 0.11em;

    &:focus,
    &:active {
      outline: 0;
    }
  }

  &__label {
    margin-bottom: rem(10);
    opacity: 0.7;
  }

  textarea {
    height: rem(150);
    resize: none;
  }

  &__icon {
    display: block;
    position: absolute;
    top: 50%;
    right: rem(10);
    transform: translateY(-50%);
    width: rem(15);
    height: rem(15);
    stroke: rgba(0, 56, 72, 0.65);
    fill: none;
  }

  &__content {
    position: relative;
  }

  &--tr {
    #{$self}__target {
      background-color: rgba(255, 255, 255, 0.8);
      color: var(--c-black);
    }
  }

  &--icon {
    #{$self}__target {
      padding-right: rem(40);
    }
  }

  &--white {
    #{$self}__target {
      padding: rem(10) rem(15);
      background-color: var(--c-white);
      font-size: rem(16);
      line-height: rem(20);
      border: 1px solid var(--c-grey);
    }

    #{$self}__icon {
      stroke: var(--c-black);
      fill: none;
    }
  }

  &--blue {
    #{$self}__target {
      padding: rem(8) rem(15);
      background-color: var(--c-white);
      font-size: rem(16);
      line-height: rem(20);
      border: 1px solid var(--c-blue);
    }

    #{$self}__icon {
      stroke: var(--c-black);
      fill: none;
    }
  }
}
