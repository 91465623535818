.logo {
  display: block;
  width: rem(378);

  @include media-breakpoint-down(md) {
    width: rem(250);
  }

  @include media-breakpoint-down(sm) {
    width: rem(200);
  }

  img {
    display: block;
    max-width: 100%;
  }
}
