.doc-card {
  text-align: center;

  &__icon {
    position: relative;
    width: rem(41);
    height: rem(45);
    margin: 0 auto;

    img {
      @include rImage();
    }
  }

  &__title {
    margin-top: rem(25);
  }

  &__btn {
    margin-top: rem(25);
  }
}
