.weather-card {
  width: rem(106);
  height: rem(106);
  padding: rem(13);
  color: var(--c-white);
  border-radius: 50%;
  border: 1px solid var(--c-blue);
  text-align: center;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var(--c-blue);
    border-radius: 50%;
  }

  &__icon {
    position: relative;
    width: rem(35);
    height: rem(35);

    img {
      @include rImage();
    }
  }
}
