.contacts-block {
  &__item {
    &:not(:last-child) {
      margin-bottom: rem(40);

      @include media-breakpoint-down(md) {
        margin-bottom: rem(20);
      }
    }
  }
}
