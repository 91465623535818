.about-page {

  &__text {
    text-align: center;
    max-width: rem(1080);
    margin-left: auto;
    margin-right: auto;
  }

  &__image {
    margin: rem(100) 0;
  }

  &__advantage {
    margin-top: rem(55);
  }
}
