.book-form {
  padding: rem(25) rem(20) rem(30);
  background: rgba(0, 56, 72, 0.65);
  color: var(--c-white);

  &__header {
    margin-bottom: rem(15);
  }

  &__title {
    font-weight: 600;
    margin-bottom: rem(5);
    text-transform: uppercase;
  }

  &__subtitle {
    font-size: rem(15);
    line-height: rem(19);
    letter-spacing: 0.11em;
  }

  &__fields {
    display: flex;
    margin: 0 rem(-7.5);
  }

  &__field {
    flex: 1 1 0;
    padding: 0 rem(7.5);
    width: 100%;
    max-width: rem(187);

    &:last-child {
      max-width: rem(259);
    }
  }

  &__submit {
    margin-top: rem(15);
  }
}
