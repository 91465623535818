.contacts-map {
  height: rem(400);
  padding: rem(5);
  border: 1px solid var(--c-white);

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: rem(-48);
    left: rem(-35);
    width: rem(133);
    height: rem(160);
    background-image: url('/assets/img/dots.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: rem(-60);
    right: rem(-35);
    width: rem(133);
    height: rem(160);
    background-image: url('/assets/img/dots.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__el {
    position: relative;
    z-index: 2;
    height: 100%;
  }
}
