.info-section {
  padding: rem(60) 0;
  background-image: url('/assets/img/figure.svg');
  background-position: left rem(100) bottom rem(-125);
  background-repeat: no-repeat;
  background-size: rem(850);

  @include media-breakpoint-down(sm) {
    background-position: left bottom rem(-125);
    background-size: rem(650);
  }

  &__title {
    max-width: rem(620);
  }
}
