:root {
  /* Colors */

  --c-white: #fff;
  --c-blue: #0057ff;
  --c-blue-hover: #004f65;
  --c-black: #000;
  --c-dark-blue: #003848;
  --c-light-grey: rgba(217, 217, 217, 0.25);
  --c-grey: #8493a2;
  --c-grey-2: #f2f2f2;
  --c-grey-3: #d0d1d2;
}
