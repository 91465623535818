.form {
  &__group {
    &:not(:last-child) {
      margin-bottom: rem(20);
    }
  }

  &__error {
    margin-top: rem(10);
  }
}
