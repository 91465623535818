.popup {
  display: none;
  padding: rem(40) rem(40);
  width: 100%;
  max-width: rem(714);

  &__title {
    text-transform: uppercase;
  }

  &__header {
    margin-bottom: rem(30);
    text-align: center;
  }
}
