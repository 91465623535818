.main-slider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &__pagination {
    position: absolute;
    z-index: 3;
    bottom: rem(55);
    left: 50%;
    transform: translateX(-50%);
  }
}
