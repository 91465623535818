.char {
  display: flex;
  font-size: rem(15);
  line-height: rem(19);
  font-weight: 600;

  &__icon {
    flex: 0 0 auto;
    position: relative;
    width: rem(24);
    height: rem(24);
    margin-right: rem(14);

    img {
      @include rImage();
      object-fit: contain;
    }
  }
}
