.map-main {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: rem(299);

  &__content {
    padding-top: rem(40);
    padding-bottom: rem(40);
    margin-left: calc((100% - #{rem(1240)}) / 2);
  }

  &__title {
    text-transform: uppercase;
    margin-bottom: rem(25);
  }

  &__btn {
    margin-top: rem(35);
  }

  &__svg {
    flex: 0 0 auto;
    width: rem(664);
    margin-left: rem(40);
    height: 100%;

    img {
      display: block;
      max-height: 100%;
    }
  }
}
