.single-room {
  &__title {
    margin-bottom: rem(30);
  }

  &__chars {
    margin-top: rem(35);
  }

  &__content {
    display: flex;
    margin-left: calc((100% - #{rem(1240)}) / 2);
  }

  &__nav {
    margin-bottom: rem(25);
  }

  &__left {
    margin-right: rem(45);
  }

  &__right {
    width: rem(828);
  }

  &__desc {
    margin-top: rem(55);
  }
}
