.burger {
  width: rem(28);

  span {
    display: block;
    width: 100%;
    height: rem(2);
    background-color: currentColor;
    transition: top 0.3s linear, transform 0.3s linear, opacity 0.1s linear;

    &:not(:last-child) {
      margin-bottom: rem(12);
    }
  }

  &._open {
    span {
      position: relative;

      &:nth-child(1) {
        transform: rotate(45deg);
        top: rem(14);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        top: rem(-14);
      }
    }
  }
}
