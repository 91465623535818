.about-section {
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: rem(30);
  }

  &__waves {
    width: rem(190);
    height: rem(41);
    margin-left: rem(70);

    svg {
      fill: none;
    }
  }

  &__title {
    color: var(--c-blue);
    text-transform: uppercase;
  }

  &__content {
    position: relative;
    z-index: 2;
    max-width: 57%;

    h3 {
      text-transform: uppercase;
      margin-bottom: rem(45);
    }
  }

  &__btn {
    margin-top: rem(46);
    text-align: center;
  }

  &__text {
    position: relative;
    margin-top: rem(30);
    padding-left: rem(20);

    &::before {
      content: '';
      position: absolute;
      top: rem(5);
      bottom: rem(5);
      left: 0;
      width: 1px;
      background-color: var(--c-black);
    }

    &:last-child {
      padding-top: rem(9);
      padding-bottom: rem(9);
      margin-left: rem(104);

      &::before {
        top: 0;
        bottom: 0;
      }
    }
  }

  &__image {
    position: absolute;
    z-index: 1;
    top: rem(63);
    right: rem(-44);
    width: rem(784);
    height: rem(583);

    img {
      @include rImage();
    }
  }

  &__mask {
    position: absolute;
    z-index: 1;
    left: rem(-45);
    bottom: 0;
    width: rem(310);
    height: rem(474);

    img {
      @include rImage();
    }
  }
}
