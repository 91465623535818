.rooms-slider {
  &__thumbs {
    margin-top: rem(50);

    &-item {
      opacity: 0.6;
      transition: opacity 0.3s linear;
      cursor: pointer;

      &.swiper-slide-thumb-active {
        opacity: 1;
      }
    }

    &-btn {
      top: calc(50% - #{rem(30)});
    }
  }
}
