.chars-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 rem(-10);

  &__item {
    max-width: 50%;
    width: 100%;
    padding: 0 rem(12);

    &:not(:nth-last-child(-n+2)) {
      margin-bottom: rem(25);
    }
  }
}
