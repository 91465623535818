.c-checkbox {
  $self: &;
  position: relative;

  &__target {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;

    &:checked + #{$self}__icon {
      svg {
        opacity: 1;
      }
    }
  }

  &__icon {
    position: absolute;
    top: rem(4);
    left: 0;
    width: rem(13);
    height: rem(13);
    border: 1px solid var(--c-blue);

    svg {
      position: absolute;
      top: rem(-7);
      right: rem(-5);
      width: rem(15);
      height: rem(17);
      fill: transparent;
      opacity: 0;
      transition: opacity 0.3s linear;
    }
  }

  &__label {
    padding-left: rem(29);
  }
}
