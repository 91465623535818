.sitemap-nav {
  &__section {
    &:not(:last-child) {
      margin-bottom: rem(35);
      padding-bottom: rem(35);
      border-bottom: 1px solid var(--c-blue);
    }
  }

  &__parent {
    font-family: 'Playfair Display', Arial, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    transition: color 0.3s linear;

    @include hover() {
      color: var(--c-blue);
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem(35);
    margin-left: rem(-10);
    margin-right: rem(-10);

    li {
      max-width: 33.33333%;
      width: 100%;
      padding: 0 rem(10);

      a {
        transition: color 0.3s linear;

        @include hover() {
          color: var(--c-blue);
        }
      }

      &:not(:nth-last-child(-n+3)) {
        margin-bottom: rem(35);
      }
    }
  }
}
