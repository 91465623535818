/**
 * Typography
 */

// Font Family
$font-family-base: "Montserrat", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-serif: "Montserrat", Cambria, "Times New Roman", Times, serif !default;
$font-family-mono: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-headings: $font-family-serif;

// Font Size
$font-size-xs: .75rem !default;
$font-size-sm: .875rem !default;
$font-size-base: 1rem !default;
$font-size-lg: 1.125rem !default;
$font-size-xl: 1.25rem !default;

// Font Weight
$font-weight-hairline: 100 !default;
$font-weight-thin: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-extrabold: 800 !default;
$font-weight-black: 900 !default;

// Line Height
$line-height-none: 1 !default;
$line-height-xs: 1.25 !default;
$line-height-sm: 1.375 !default;
$line-height-base: 1.5 !default;
$line-height-lg: 1.625 !default;
$line-height-xl: 2 !default;

// Headings
$headings-margin-bottom: 1rem !default;
$headings-font-family: $font-family-headings !default;
$headings-font-weight: $font-weight-bold !default;
$headings-letter-spacing: normal !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;
$headings-text-transform: inherit !default;

// Headings Responsive Size
$h1-font-size: ( xs: rem(30), sm: rem(30), md: rem(30), lg: rem(56), xl: rem(56) );
$h2-font-size: ( xs: 22px, sm: 24px, md: 26px, lg: 28px, xl: 32px, xxl: 36px );
$h3-font-size: ( xs: 20px, sm: 22px, md: 24px, lg: 26px, xl: 28px, xxl: 32px );
$h4-font-size: ( xs: 18px, sm: 20px, md: 22px, lg: 24px, xl: 26px, xxl: 28px );
$h5-font-size: ( xs: 20px, sm: 20px, md: 20px, lg: 22px, xl: 24px, xxl: 24px );
$h6-font-size: ( xs: 16px, sm: 16px, md: 16px, lg: 18px, xl: 20px, xxl: 20px );

// List
$list-margin-bottom: 1rem !default;

// Paragraph
$paragraph-margin-bottom: 1rem !default;
