.advantage-card {
  text-align: center;

  &__img {
    position: relative;
    width: rem(40);
    height: rem(40);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(25);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}
