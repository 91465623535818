.rooms-card {
  $self: &;

  &__title {
    margin-bottom: rem(10);
  }

  &__services {
    margin-bottom: rem(25);
  }

  &__text {
    font-size: rem(15);
    line-height: rem(23);
  }

  &__price {
    margin-top: rem(11);
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    margin-top: rem(25);
  }

  &__btn {
    &:not(:last-child) {
      margin-right: rem(25);
    }
  }

  &--slider {
    min-height: rem(468);

    #{$self}__inner {
      display: flex;
    }

    #{$self}__left {
      flex: 0 0 auto;
      width: rem(682);
    }

    #{$self}__right {
      padding: rem(40);
      background-color: var(--c-white);
    }
  }
}
