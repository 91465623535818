.contacts-page {
  &__info {
    max-width: rem(1080);
    margin: 0 auto;
  }

  &__socials {
    margin-top: rem(45);
    text-align: center;

    &-title {
      margin-bottom: rem(25);
    }
  }

  &__map {
    margin-top: rem(45);
  }
}
