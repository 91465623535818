.news-card {
  $self: &;

  position: relative;
  text-align: center;

  &__image {
    position: relative;
    height: rem(343);
    margin-bottom: rem(30);

    img {
      @include rImage();
    }
  }

  &__text {
    margin-bottom: rem(15);

    span {
      font-weight: 600;
    }
  }

  &__title {
    transition: color 0.3s linear;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }

  @include hover() {
    #{$self}__title {
      color: var(--c-blue);
    }
  }
}
