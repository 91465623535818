.contacts-card {
  text-align: center;

  &__icon {
    position: relative;
    width: rem(36);
    height: rem(36);
    margin: 0 auto rem(25);

    img {
      @include rImage();
      object-fit: contain;
    }
  }

  &__title {
    margin-bottom: rem(10);
  }

  &__text {
    p {
      &:not(:last-child) {
        margin-bottom: rem(5);
      }
    }

    a {
      color: var(--c-blue);
      font-weight: 600;
    }
  }
}
