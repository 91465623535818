.reviews-card {
  display: flex;

  &__image {
    flex: 0 0 auto;
    position: relative;
    width: rem(75);
    height: rem(75);
    margin-right: rem(25);
    overflow: hidden;
    border-radius: 50%;

    img {
      @include rImage();
    }
  }

  &__name {
    margin-top: rem(15);
    font-weight: 600;
    text-transform: uppercase;
  }
}
