.footer {
  padding: rem(22) 0;
  line-height: rem(18);

  &__inner {
    display: flex;

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }
  }

  &__logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(14) rem(18);
    border-right: 1px solid rgba(#c4c4c4, 0.4);
    border-left: 1px solid rgba(#c4c4c4, 0.4);

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(14) rem(40);
    border-right: 1px solid rgba(#c4c4c4, 0.4);
    border-left: 1px solid rgba(#c4c4c4, 0.4);
    margin-left: -1px;
    font-size: rem(18);
    line-height: rem(20);

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: rem(14) rem(25);
    margin-left: auto;
    border-right: 1px solid rgba(#c4c4c4, 0.4);
    border-left: 1px solid rgba(#c4c4c4, 0.4);

    @include media-breakpoint-down(sm) {
      margin-left: 0;
      width: 100%;
      align-items: center;
    }
  }
}
