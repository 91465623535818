.numbers-card {
  display: flex;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  &__val {
    position: relative;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: rem(28);
    width: rem(95);
    height: rem(95);
    color: var(--c-white);
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 700;
    font-size: rem(46);
    line-height: rem(32);

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      margin-bottom: rem(20);
      width: rem(65);
      height: rem(65);
      font-size: rem(36);
      line-height: 1;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      width: rem(95);
      height: rem(95);
      border-radius: 50%;
      background-color: var(--c-blue);

      @include media-breakpoint-down(sm) {
        width: rem(65);
        height: rem(65);
      }
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: rem(8);
      left: rem(8);
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba(0, 87, 255, 0.45);

      @include media-breakpoint-down(sm) {
        width: rem(65);
        height: rem(65);
        top: rem(4);
        left: rem(4);
      }
    }

    span {
      position: relative;
      z-index: 3;
    }
  }

  &__text {
    h3 {
      color: var(--c-blue);
    }
  }

  &__content {
    &-item {
      &:not(:last-child) {
        @include media-breakpoint-down(md) {
          margin-bottom: rem(15);
        }
      }
    }
  }
}
