.pill-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: rem(14);
  line-height: rem(21);

  &__title {
    position: relative;
    padding-right: rem(67);
    margin-right: rem(24);
    margin-bottom: rem(20);

    &::after {
      content: '';
      position: absolute;
      top: rem(11);
      right: 0;
      width: rem(42);
      height: rem(1);
      background-color: rgba(175, 176, 178, 0.7);
    }
  }

  &__item {
    margin-bottom: rem(20);

    &:not(:last-child) {
      margin-right: rem(27);
    }
  }

  &__link {
    flex: 0 0 auto;
    display: block;
    padding: rem(8) rem(15) rem(7);
    border-radius: rem(3);
    background-color: var(--c-grey-3);
    transition: background-color 0.3s linear, color 0.3s linear;

    @include hover() {
      background-color: var(--c-blue);
      color: var(--c-white);
    }

    &._active {
      background-color: var(--c-blue);
      color: var(--c-white);
    }
  }
}
