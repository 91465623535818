.small-room {
  &__image {
    position: relative;
    margin-bottom: rem(10);
    height: rem(113);

    img {
      @include rImage();
    }
  }

  &__title {
    margin-bottom: rem(10);
  }

  &__price {
    color: var(--c-blue);
    font-size: rem(15);
    line-height: rem(19);
    letter-spacing: 0.11em;

    span {
      font-weight: 600;
    }
  }
}
