.slider-pagination {
  $self: &;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__dot {
    position: relative;
    flex: 0 0 auto;
    width: rem(10);
    height: rem(10);

    &:not(:last-child) {
      margin-right: rem(10);
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: rem(10);
      height: rem(10);
      border-radius: 50%;
      background-color: var(--c-white);
      cursor: pointer;
    }

    &--active {
      width: rem(24);
      height: rem(24);

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        border: 1px solid var(--c-white);
        border-radius: 50%;
      }
    }
  }

  &--dark {
    justify-content: center;

    #{$self}__dot {

      &::before {
        background-color: rgba(0, 0, 0, 0.7);
      }

      &--active {
        width: rem(10);
        height: rem(10);

        &::before {
          background-color: rgba(0, 0, 0, 0.4);
        }

        &::after {
          display: none;
        }
      }
    }
  }
}
