.sert-card {
  margin-left: auto;
  margin-right: auto;
  width: rem(235);
  height: rem(340);

  &__image {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;

    img {
      @include rImage();
    }
  }
}
