.form {

  &__row {
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: rem(25);
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--quality {
    max-width: rem(1040);
    margin: 0 auto;
  }

  &--book {
    max-width: rem(1040);
    margin: 0 auto;
  }
}
