.image-slider {
  $self: &;
  position: relative;
  height: 100%;
  width: 100%;

  &__item {
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;

    img {
      @include rImage();
    }
  }

  &--small {
    height: rem(479);
  }

  &--bottom {
    #{$self}__btn {
      top: auto;
      bottom: rem(30);
      transform: none;

      &--next {
        right: rem(100);
      }

      &--prev {
        left: rem(100);
      }
    }
  }
}
