.navigation {
  $self: &;

  &__item {
    &:not(:last-child) {
      margin-bottom: rem(20);
    }

    & > ul {
      position: absolute;
      top: 0;
      left: 100%;
      width: rem(288);
      height: 100%;
      padding: rem(174) rem(30) rem(30);
      background: rgba(0, 56, 72, 0.9);
      overflow: auto;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transition: opacity 0.3s linear, visibility 0.3s linear;

      li {
        &:not(:last-child) {
          margin-bottom: rem(20);
        }

        a {
          position: relative;
          display: block;
          text-transform: uppercase;
          transition: color 0.3s linear;

          @include hover() {
            color: var(--c-blue);
          }
        }
      }
    }

    &._open {
      & > ul {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }

      #{$self}__icon {
        transform: rotate(180deg);
      }

      #{$self}__link {
        color: var(--c-blue);
      }
    }
  }

  &__icon {
    position: absolute;
    width: rem(7);
    height: rem(11);
    right: 0;
    top: rem(6);
    fill: transparent;
    transition: transform 0.3s linear;
  }

  &__link {
    position: relative;
    display: block;
    text-transform: uppercase;
    transition: color 0.3s linear;

    @include hover() {
      color: var(--c-blue);
    }
  }
}
