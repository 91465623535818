.c-select {
  &__label {
    margin-bottom: rem(10);
    opacity: 0.7;
  }
}

.custom-select-container {
  position: relative;
}

.custom-select-container.is-disabled {
  opacity: .333;
}

.custom-select-opener {
  display: block;
  padding: rem(10) rem(15);
  width: 100%;
  background-color: var(--c-white);
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: rem(17);
    right: rem(15);
    width: rem(12);
    height: rem(7);
    background: url('/assets/img/icons/arrow.svg') no-repeat center center / 100%;
    transition: transform 0.3s linear;
  }
}

.custom-select-container select {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.custom-select-panel {
  position: absolute;
  z-index: 10;
  top: rem(40);
  left: 0;
  width: 100%;
  display: none;
  max-height: rem(120);
  overflow: auto;
  background-color: var(--c-white);
}

.custom-select-container.is-open .custom-select-panel {
  display: block;
}

.custom-select-container.is-open .custom-select-opener {
  &::after {
    transform: rotate(180deg);
  }
}

.custom-select-option {
  padding: rem(10);
  transition: background-color 0.3s linear, color 0.3s linear;
  cursor: pointer;
}

.custom-select-option.has-focus {
  background-color: var(--c-blue);
  color: var(--c-white);
}

.custom-select-option.is-selected {
  background-color: var(--c-blue);
  color: var(--c-white);
}

.custom-select-optgroup > .custom-select-option {
  padding-left: 2em;
}

.custom-select-optgroup::before {
  content: attr(data-label);
  display: block;
  padding: 0.5em;
  color: #888;
}
