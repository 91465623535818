.page {
  &__title {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__subtitle {
    max-width: rem(1080);
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(10);
  }

  &__header {
    margin-bottom: rem(55);
    text-align: center;
  }

  &__nav {
    margin-bottom: rem(25);
  }

  &__gallery {
    max-width: 828px;
    margin: 0 auto;

    &-title {
      text-align: center;
      margin-bottom: rem(30);
    }
  }

  &__gallery + &__text {
    text-align: center;
    margin-top: rem(45);
  }

  &__btns {
    display: flex;
    margin-bottom: rem(45);
  }

  &__btn {
    min-width: rem(277);

    &:not(:last-child) {
      margin-right: rem(30);
    }
  }

  &__form {
    margin-bottom: rem(55);
  }

  &__list {
    max-width: rem(1080);
    margin: 0 auto;
  }
}
