/*!
 * Project: Project Name
 * Author: Name
 * E-mail: E-mail
 * Website:
 */

/**
 * The structure is based on ITCSS with custom modifications.
 *
 * This is where all of the stylesheets are compiled.
 * They are processed in the order they are imported
 * to ensure the consistent increase of specificity.
 *
 *    ITCSS Methodology: Inverted Triangle CSS
 *
 *    ****************   1. Settings
 *     **************    2. Tools
 *      ************     3. Generic
 *       **********      4. Elements
 *        ********       5. Objects
 *         ******        6. Components
 *          ****         7. Modules
 *           **          8. Utilities
 *
 * 1. Settings
 *    Global configuration and variables.
 *    Breakpoints, colors, spacing, utilities etc.
 *
 * 2. Tools
 *    Functions and mixins.
 *
 * 3. Generic
 *    Ground zero styles. No classes.
 *
 * 4. Elements
 *    Unclassed (bare) HTML element.
 *    H1, Ul, A etc.
 *
 * 5. Objects
 *    Common non-cosmetic structural design patterns.
 *    Containers, rows, grids, colums etc.
 *
 * 6. Components
 *    Specific cosmetic elements of UI.
 *    Buttons, forms etc.
 *
 * 7. Modules
 *    Multi-part components.
 *    Navbar, footer etc.
 *
 * 8. Utilities
 *    Helpers and overrides.
 *
 *    Shame
 *    All the CSS, hacks and things we are not proud of.
 */
@import "swiper";
@import "fancybox";
@import "0-mixins/_functions.scss";
@import "1-settings/_breakpoints.scss";
@import "1-settings/_colors.scss";
@import "1-settings/_global.scss";
@import "1-settings/_reset.scss";
@import "1-settings/_spacing.scss";
@import "1-settings/_typography.scss";
@import "1-settings/_utilities.scss";
@import "1-settings/_vars.scss";
@import "2-tools/_breakpoints.scss";
@import "2-tools/_generate-utility.scss";
@import "2-tools/_make-responsive.scss";
@import "2-tools/_mixins.scss";
@import "2-tools/_ratio.scss";
@import "3-generic/_font-face.scss";
@import "3-generic/_globals.scss";
@import "3-generic/_print.scss";
@import "3-generic/_sanitize.scss";
@import "3-generic/_text.scss";
@import "4-elements/_icon.scss";
@import "4-elements/_link.scss";
@import "4-elements/_logo.scss";
@import "4-elements/_menu.scss";
@import "4-elements/_prices.scss";
@import "4-elements/_weather-card.scss";
@import "5-objects/_bootstrap.grid.scss";
@import "5-objects/_container..scss";
@import "5-objects/_section.scss";
@import "5-objects/_wrapper.scss";
@import "6-components/_advantage-card.scss";
@import "6-components/_all-card.scss";
@import "6-components/_btn-message.scss";
@import "6-components/_burger.scss";
@import "6-components/_buttons.scss";
@import "6-components/_c-checkbox.scss";
@import "6-components/_c-input.scss";
@import "6-components/_char.scss";
@import "6-components/_contact-card.scss";
@import "6-components/_contacts-card.scss";
@import "6-components/_contacts-map.scss";
@import "6-components/_contacts.scss";
@import "6-components/_custom-select.scss";
@import "6-components/_doc-card.scss";
@import "6-components/_file-input.scss";
@import "6-components/_footer.scss";
@import "6-components/_form.scss";
@import "6-components/_header.scss";
@import "6-components/_info-section.scss";
@import "6-components/_lang-switch.scss";
@import "6-components/_main-slide.scss";
@import "6-components/_news-card.scss";
@import "6-components/_numbers-card.scss";
@import "6-components/_page-header.scss";
@import "6-components/_pane.scss";
@import "6-components/_pill-nav.scss";
@import "6-components/_popup.scss";
@import "6-components/_reviews-card.scss";
@import "6-components/_rooms-card.scss";
@import "6-components/_sert-card.scss";
@import "6-components/_services.scss";
@import "6-components/_sitemap-nav.scss";
@import "6-components/_slider-navigation-btn.scss";
@import "6-components/_slider-pagination.scss";
@import "6-components/_small-room.scss";
@import "6-components/_socials.scss";
@import "6-components/_white-card.scss";
@import "6-components/_work-card.scss";
@import "7-modules/_about-page.scss";
@import "7-modules/_about-section.scss";
@import "7-modules/_advantages-list.scss";
@import "7-modules/_all-list.scss";
@import "7-modules/_all-page.scss";
@import "7-modules/_book-form.scss";
@import "7-modules/_chars-list.scss";
@import "7-modules/_contacts-block.scss";
@import "7-modules/_contacts-page.scss";
@import "7-modules/_docs-list.scss";
@import "7-modules/_form.scss";
@import "7-modules/_image-slider.scss";
@import "7-modules/_main-contacts.scss";
@import "7-modules/_main-section.scss";
@import "7-modules/_main-slider.scss";
@import "7-modules/_map-main.scss";
@import "7-modules/_nav-pane.scss";
@import "7-modules/_navigation.scss";
@import "7-modules/_news-list.scss";
@import "7-modules/_news-section.scss";
@import "7-modules/_numbers-list.scss";
@import "7-modules/_page.scss";
@import "7-modules/_reviews-slider.scss";
@import "7-modules/_rooms-list.scss";
@import "7-modules/_rooms-slider.scss";
@import "7-modules/_single-room.scss";
@import "7-modules/_weather-block.scss";
@import "7-modules/_work-block.scss";
@import "7-modules/_work-list.scss";
@import "8-utilities/_ratio.scss";
@import "8-utilities/_utilities.scss";
@import 'shame';
