.work-card {
  position: relative;
  height: rem(460);
  background-color: var(--c-light-grey);

  @include media-breakpoint-down(sm) {
    height: rem(300);
  }

  &__title {
    position: absolute;
    top: rem(24);
    left: rem(15);
    font-size: rem(40);
    line-height: 1;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--c-white);
    pointer-events: none;
    user-select: none;
    writing-mode: vertical-rl;

    @include media-breakpoint-down(sm) {
      font-size: rem(26);
    }
  }
}
